
import { getSliceComponentProps } from '@prismicio/vue/components';
import editoCarouselAdapter from '@/slices/EditoCarousel/adapter';
import editoCarouselTaxonomyAdapter from '@/slices/EditoCarousel/taxonomyAdapter';
import editoCarouselAllTaxonomyAdapter from '@/slices/EditoCarousel/allTaxonomyAdapter';

export default {
  name: 'EditoCarousel',
  props: getSliceComponentProps(['slice', 'index', 'slices', 'context']),
  data() {
    return {
      sliceData: null,
    };
  },
  async fetch() {
    try {
      const isTaxonomyVariation = this.slice.variation === 'taxonomies';
      const listAllGuides = this.slice.primary?.list_all;

      if (isTaxonomyVariation && listAllGuides) {
        const result = await editoCarouselAllTaxonomyAdapter(
          this.slice,
          this.$prismic,
          this.$i18n,
          this.$enhancedLinkSerializer
        );
        this.sliceData = result;
        return;
      }

      const adapter = isTaxonomyVariation
        ? editoCarouselTaxonomyAdapter
        : editoCarouselAdapter;

      const data = await adapter(this.slice, {
        i18n: this.$i18n,
        prismic: this.$prismic,
        enhancedLinkSerializer: this.$enhancedLinkSerializer,
      });

      data.cards = data.cards.length
        ? data.cards.filter((card) => card.title !== this.context.title[0].text)
        : [];
      this.sliceData = data;
    } catch (e) {
      console.log({ e });
    }
  },
};
