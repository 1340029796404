import { stripRichTextWrapperTag } from '@/utils/html';
import {
  routerLinks,
  offerLinks,
  countryLinks,
  regionLinks,
} from '@/utils/fetch-links';
import {
  getType,
  getCardType,
  cardCountryAdapter,
  cardRegionAdapter,
  cardGuideAdapter,
  cardArticleAdapter,
} from '@/utils/helpers/get-cards';

const getItems = async (type, offer, prismic, lang) => {
  const queryType = getType(type);

  const query = [prismic.predicates.at('document.type', queryType)];
  if (offer?.id) {
    query.push(prismic.predicates.at(`my.${queryType}.offer`, offer.id));
  }

  const items = await prismic.api.query(query, {
    orderings: `[my.${queryType}.date desc, document.last_publication_date desc]`,
    pageSize: 4,
    fetchLinks: [
      ...routerLinks,
      ...offerLinks,
      ...countryLinks,
      ...regionLinks,
    ],
    lang,
  });

  return items.results.map((result) => ({
    edito_carousel_card: { ...result, link_type: 'Document' },
  }));
};

export const cardsAdapter = (cards, i18n, enhancedLinkSerializer) => {
  return cards.map(({ edito_carousel_card: editoCarouselCard }) => {
    const { type } = editoCarouselCard;
    const cardType = getCardType(type);
    const adatpterData = {
      editoCarouselCard,
      cardType,
      i18n,
      enhancedLinkSerializer,
    };

    switch (cardType) {
      case 'guide':
        return cardGuideAdapter(adatpterData);
      case 'region':
        return cardRegionAdapter(adatpterData);
      case 'country':
        return cardCountryAdapter(adatpterData);
      case 'article':
      default:
        return cardArticleAdapter(adatpterData);
    }
  });
};

export default async (
  { primary, items },
  { i18n, prismic, enhancedLinkSerializer }
) => {
  const lang = i18n.localeProperties.iso;
  const type = primary.edito_carousel_type || 'article';
  const offer = primary.edito_carousel_offer;
  const filteredItems = items.filter(
    ({ edito_carousel_card: editoCarouselCard }) => editoCarouselCard.data
  );
  let cardItems = [];

  if (filteredItems.length > 0) {
    cardItems = filteredItems;
  } else if (type) {
    cardItems = await getItems(type, offer, prismic, lang);
  }

  return {
    featured: primary.edito_carousel_featured,
    title: stripRichTextWrapperTag(primary.edito_carousel_title),
    link: enhancedLinkSerializer(primary.edito_carousel_link),
    linkLabel: primary.edito_carousel_link_label,
    withCarousel: primary.edito_carousel_active,
    cards: cardsAdapter(cardItems, i18n, enhancedLinkSerializer),
  };
};
